/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-1/3 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Queue Item Names</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer Drag239834441344614400" v-for="i in queues" :key="i">{{ i }}</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-2/3 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-bold">Process Order</h2>
                </div>
                <div class="vx-col w-3/4 flex items-center">
                  <h2 class="font-bold">Item Name</h2>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <img
                    draggable="false"
                    src="https://cdn.discordapp.com/attachments/760235797424373840/848331928285216798/number-one.png"
                    width="50"
                    class="mr-4"
                  />
                  <h2 class="font-semibold">First</h2>
                </div>
                <div class="vx-col w-3/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <img
                    draggable="false"
                    src="https://cdn.discordapp.com/attachments/746242308084858900/848779011714842634/016-number_2.png"
                    width="50"
                    class="mr-4"
                  />
                  <h2 class="font-semibold">Second</h2>
                </div>
                <div class="vx-col w-3/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <img
                    draggable="false"
                    src="https://cdn.discordapp.com/attachments/746242308084858900/848779013488902144/017-number_3.png"
                    width="50"
                    class="mr-4"
                  />
                  <h2 class="font-semibold">Third</h2>
                </div>
                <div class="vx-col w-3/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <img
                    draggable="false"
                    src="https://cdn.discordapp.com/attachments/746242308084858900/848779008195035176/031-number_four.png"
                    width="50"
                    class="mr-4"
                  />
                  <h2 class="font-semibold">Fourth</h2>
                </div>
                <div class="vx-col w-3/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <img
                    draggable="false"
                    src="https://cdn.discordapp.com/attachments/746242308084858900/848779010480930836/032-number_5.png"
                    width="50"
                    class="mr-4"
                  />
                  <h2 class="font-semibold">Fifth</h2>
                </div>
                <div class="vx-col w-3/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input4">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      queues: ['EmployeeID1', 'EmployeeID2', 'EmployeeID3', 'EmployeeID4', 'EmployeeID5'],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'EmployeeID2') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'EmployeeID5') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'EmployeeID3') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'EmployeeID1') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'EmployeeID4') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.Drag239834441344614400 {
  font-size: 23px;
}
</style>
